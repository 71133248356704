<template>
  <div class="modal fade" ref="mdlPing" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header text-white bg-success">
          <h5 class="modal-title text-center text-white">
            <strong>Resultados de las pruebas de ping</strong>
            &nbsp;
            <div style="height: 30px; display: inline-block">
              <img 
                :src="API +'/cpes/modelos/'+cpe.id_modelo+'/imagen?tipo_foto=64x64&_tk='+tk"
                v-if="cpe.id != null && cpe.id != 'null' && cpe.modelo.id_foto != null && cpe.modelo.id_foto != 'null'"
                class="iconcpe"

                style="width:30px; height:30px;"
              />
            </div>
            <span
              class="text-primary"
              v-if="cpe.en_linea == true"
              title="Conectado"
            >
              <i class="fa fa-signal"></i>
            </span>
            <span
              class="text-danger"
              v-if="cpe.en_linea == false"
              title="Desconectado"
            >
              <i class="fa fa-exclamation-circle"></i>
            </span>
            &nbsp;
            <strong>{{cpe.nombre}}</strong>
          </h5>
          <button
            type="button" class="btn-close"
            data-bs-dismiss="modal" aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text">
                      Dirección IP del CPE
                    </span>
                    <span class="form-control text-center">
                      {{ cpe.ip_wan }}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text">
                      Cantidad de pruebas
                    </span>
                    <input
                      type="number"
                      placeholder="0"
                      min="0"
                      class="form-control text-end"
                      v-model="cantidad"
                      oninput="this.value = Math.abs(this.value)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 text-end">
              <button class="btn btn-success" @click="cargarPruebasPing()">
                <i class="mdi mdi-radar"></i>
                Ping
              </button>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-12">
              <span v-if="pruebas_ping.length > 0">
                Haciendo ping a <strong>{{cpe.ip_wan}}</strong> con <strong>56</strong> byts de datos:
              </span>
              <div class="table-responsive" style="min-height: 100px">
                <table class="table table-hover table-nowrap align-middle" id="tabla-ping">
                  <tbody>
                    <tr v-for="(ping,index) in pruebas_ping" :key="index">
                      <template v-if="ping.hasOwnProperty('status')">
                        <td>
                          Respuesta desde <strong>{{ ping.host }}</strong> icmp_seq=<strong>{{ ping.sent }}</strong> Host de destino inalcanzable
                        </td>
                      </template>
                      <template v-if="!ping.hasOwnProperty('status')">
                        <td>
                          <strong>{{ ping.size }}</strong> bytes desde <strong>{{ ping.host }}</strong>:
                          icmp_seq=<strong>{{ ping.sent }}</strong>
                          ttl=<strong>{{ ping.ttl }}</strong>
                          tiempo=<strong>{{ convertirAMilisegundos(ping.time) }}ms</strong>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <br>
              <div v-if="pruebas_ping.length > 0">
                <span>
                  --- Estadísticas de ping <strong>{{cpe.ip_wan}}</strong> ---
                </span>

                <br><br>

                <span>
                  <strong>{{total_paquetes}}</strong> paquetes transmitidos,
                </span>

                <span>
                  <strong>{{paquetes_recibidos}}</strong> recibidos,
                </span>

                <span v-if="errores_paquetes > 0">
                  <strong>{{errores_paquetes}}</strong> errores,
                </span>

                <span>
                  <strong>{{porcentaje_perdida_paquetes}}</strong>% de pérdida de paquetes{{ tiempo_total > 0 ? ',' : '' }}
                </span>

                <span v-if="tiempo_total > 0">
                  tiempo <strong>{{tiempo_total}}ms</strong>
                </span>

                <br><br>

                <div>
                  <span v-if="minimo_rtt > 0 && minimo_rtt !== Infinity">
                    RTT min: <strong>{{minimo_rtt}}</strong>ms,
                  </span>

                  <span v-if="maximo_rtt > 0 && maximo_rtt !== Infinity">
                    RTT max: <strong>{{maximo_rtt}}</strong>ms,
                  </span>

                  <span v-if="promedio_rtt > 0">
                    RTT promedio: <strong>{{promedio_rtt}}</strong>ms,
                  </span>

                  <span v-if="media_rtt > 0 && media_rtt !== NaN">
                    RTT media: <strong>{{media_rtt}}</strong>ms,
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import CpeSrv from '@/services/CpeSrv.js'
import API from '@/API.js'
export default {
  name: 'MdlPing',
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      id_cpe: null,
      cpe: {
        id: null,
        ip_wan: null,
        nombre: '',
        en_linea: false,
        id_modelo: null,
        modelo: {
          id_foto: null
        }
      },
      cantidad: 3,
      pruebas_ping: [],
      total_paquetes: 0,
      paquetes_recibidos: 0,
      errores_paquetes: 0,
      porcentaje_perdida_paquetes: 0,
      tiempo_total: 0,


      promedio_rtt: 0,
      maximo_rtt: 0,
      minimo_rtt: 0,
      media_rtt: 0
    }
  },
  methods: {
    cargarCpe: function() {
      var self = this
      var idCpe = this.id_cpe

      CpeSrv.cpeJSON(idCpe, { con_modelo: true }).then(response => {
        let cpe = response.data
        Object.assign(self.cpe, cpe)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el CPE'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPruebasPing: function() {
      var self = this
      var idCpe = this.id_cpe

      var params = {
        cantidad: self.cantidad
      }

      iu.spinner.mostrar("#tabla-ping");

      self.pruebas_ping = []

      CpeSrv.ping(idCpe, params).then(response => {
        var pruebas = response.data

        let total = pruebas.length
        let recibidos = 0
        let errores = 0
        let tiempo_total = 0


        let avg_rtt = []
        let max_rtt = []
        let min_rtt = []

        pruebas.forEach(p => {
          if(p.received > 0) recibidos += 1
          else errores += 1

          if(p.hasOwnProperty('time')) {
            tiempo_total += self.convertirAMilisegundos(p.time)
          }

          if(p.hasOwnProperty('avg-rtt')) {
            avg_rtt.push(self.convertirAMilisegundos(p["avg-rtt"]))
          }

          if(p.hasOwnProperty('max-rtt')) {
            max_rtt.push(self.convertirAMilisegundos(p["max-rtt"]))
          }

          if(p.hasOwnProperty('min-rtt')) {
            min_rtt.push(self.convertirAMilisegundos(p["min-rtt"]))
          }
        })

        self.total_paquetes = total
        self.paquetes_recibidos = recibidos
        self.errores_paquetes = errores
        self.porcentaje_perdida_paquetes = (errores * 100) / total

        self.maximo_rtt = Math.max(...max_rtt)
        self.minimo_rtt = Math.min(...avg_rtt)

        let promedio = avg_rtt.reduce((acc, num) => acc + num, 0) / avg_rtt.length
        self.promedio_rtt = parseFloat(promedio.toFixed(3))

        let media = (self.promedio_rtt + self.maximo_rtt + self.minimo_rtt) / 3
        self.media_rtt = parseFloat(media.toFixed(3))

        self.tiempo_total = parseFloat(tiempo_total.toFixed(3))

        self.pruebas_ping = pruebas
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las pruebas de ping'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar("#tabla-ping");
      })
    },






    // Función para convertir las cadenas en milisegundos
    convertirAMilisegundos: function(cadena) {
      const msMatch = cadena.match(/(\d+)ms/);  // Busca los valores de ms
      const usMatch = cadena.match(/(\d+)us/);  // Busca los valores de us

      let milisegundos = 0;

      // Si encontramos un valor en ms, lo sumamos
      if (msMatch) {
        milisegundos += parseInt(msMatch[1]);
      }

      // Si encontramos un valor en us, lo convertimos a milisegundos (1 us = 0.001 ms)
      if (usMatch) {
        milisegundos += parseInt(usMatch[1]) * 0.001;
      }

      return parseFloat(milisegundos.toFixed(3));
    },

    mostrar(idCpe) {
      var self = this
      self.id_cpe = idCpe
      self.cantidad = 3
      self.pruebas_ping = []
      self.cargarCpe()
      self.cargarPruebasPing()
      const modal = new Modal(this.$refs.mdlPing)
      modal.show()
    }
  }
}
</script>


<style scoped>
.iconcpe {
  width:30px;
  height:30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>