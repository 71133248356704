<template>
  <Layout
    :tituloPagina="`Network | CPEs${router.nombre != '' ? ' del ' + router.nombre : ''}
    ${router.nombre != '' && isp.nombre != '' ? ', ' : ''}
    ${isp.nombre != '' ? ' del ' + isp.nombre : ''}`"
  >
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones seccion="cpes"></cmp-secciones>
      </div>
    </div>

    <div class="row">
      <!-- filtros CPEs -->
      <div class="col-md-3 col-3 mb-3">
        <button class="btn btn-white" @click="mostrarMdlFiltrosCpes()">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-format-list-bulleted"></i>
            Filtros
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-format-list-bulleted"></i>
          </span>
        </button>
      </div>
      <div class="col-md-6 col-6 mb-3">
        <filtrador
          v-on:dato-actualizado="(dato = $event), refrescarCpes()"
          :datoini="dato"
          placeholder="Dato del CPE"
        ></filtrador>
      </div>
      <div class="col-md-3 col-3 text-right mb-3">
        <router-link :to="{ name: 'nuevoCpe' }" class="btn btn-success">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3 mb-3">
        <div class="d-flex flex-wrap gap-2">
          <button
            class="btn btn-white btn-sm"
            :disabled="ids_cpes_seleccionados.length == 0"
            @click="mostrarMdlCambiarIsp()"
            title="Cambiar de ISP"
          >
            <span class="d-none d-sm-block">
              <i class="mdi mdi-sync"></i>
              Cambiar de ISP
            </span>
            <span class="d-block d-sm-none">
              <i class="mdi mdi-sync"></i>
              ISP
            </span>
          </button>
          <button
            class="btn btn-white btn-sm"
            @click="mostrarMdlColumnas()"
            title="Columnas"
          >
            <span class="d-none d-sm-block">
              <i class="mdi mdi-ballot-outline"></i>
              Columnas
            </span>
            <span class="d-block d-sm-none">
              <i class="mdi mdi-ballot-outline"></i>
            </span>
          </button>
        </div>
      </div>
      <div class="col-md-6 text-center mb-3">
        <div class="form-check" style="display: inline-block">
          <input
            class="form-check-input form-check-input-success"
            type="radio" name="filtros" id="todos" v-model="filtros" 
            :value="'todos'" @change="refrescarCpes()"
          />
          <label
            class="form-check-label"
            :class="{ 'text-success': filtros == 'todos' }"
            for="todos"
          >
            <strong>Todos</strong>
          </label>
        </div>
        &nbsp;
        <div class="form-check" style="display: inline-block">
          <input
            class="form-check-input form-check-input-primary"
            type="radio" name="filtros" id="conectados" v-model="filtros"
            :value="'conectados'" @change="refrescarCpes()"
          />
          <label
            class="form-check-label"
            :class="{ 'text-primary': filtros == 'conectados' }"
            for="conectados"
          >
            <strong>Conectados</strong>
          </label>
        </div>
        &nbsp;
        <div class="form-check" style="display: inline-block">
          <input
            class="form-check-input form-check-input-danger"
            type="radio" name="filtros" id="suspendidos" v-model="filtros"
            :value="'suspendidos'" @change="refrescarCpes()"
          />
          <label
            class="form-check-label"
            :class="{ 'text-danger': filtros == 'suspendidos' }"
            for="suspendidos"
          >
            <strong>Suspendidos</strong>
          </label>
        </div>
        &nbsp;
        <div class="form-check" style="display: inline-block">
          <input
            class="form-check-input form-check-input-danger"
            type="radio" name="filtros" id="desconectados"
            v-model="filtros" :value="'desconectados'"
            @change="refrescarCpes()"
          />
          <label
            class="form-check-label"
            :class="{ 'text-danger': filtros == 'desconectados' }"
            for="desconectados"
          >
            <strong>Desconectados</strong>
          </label>
        </div>
        &nbsp;
        <div class="form-check" style="display: inline-block">
          <input
            class="form-check-input form-check-input-warning"
            type="radio" name="filtros" id="enReparacion"
            v-model="filtros" :value="'en_reparacion'"
            @change="refrescarCpes()"
          />
          <label
            class="form-check-label"
            :class="{ 'text-warning': filtros == 'en_reparacion' }"
            for="enReparacion"
          >
            <strong>En reparación</strong>
          </label>
        </div>
        &nbsp;
        <div class="form-check" style="display: inline-block">
          <input
            class="form-check-input form-check-input-info"
            type="radio" name="filtros" id="descompuestos"
            v-model="filtros" :value="'descompuestos'"
            @change="refrescarCpes()"
          />
          <label
            class="form-check-label"
            :class="{ 'text-info': filtros == 'descompuestos' }"
            for="descompuestos"
          >
            <strong>Descompuestos</strong>
          </label>
        </div>
      </div>
      <div class="col-md-3 text-right">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{ nCpesEnPag }} cpes en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12 ms-2">
          {{ nCpesTotales }} cpes en total
        </span>
      </div>
    </div>

    <br />

    <div class="card">
      <div class="card-body" style="min-height: 475px">
        <div class="table-responsive" style="min-height: 475px">
          <table class="table table-hover table-nowrap align-middle" id="tabla-cpes">
            <thead>
              <tr>
                <th style="width: 20px">
                  <input
                    type="checkbox"
                    ref="palancaSeleccionarTodosCpes"
                    @change="palancaSeleccionarTodosCpes()"
                  />
                </th>
                <th style="width: 80px"></th>
                <th style="width: 40px">ID</th>
                <th>Nombre de CPE</th>
                <th v-show="columnas.sitio">Sitio</th>
                <th v-show="columnas.ip_wan">IP WAN</th>
                <th v-show="columnas.mac" style="width: 150px">MAC</th>
                <th v-show="columnas.cliente">Cliente</th>
                <th v-show="columnas.estado" style="width: 150px">Estado</th>
                <th v-show="columnas.servicio" style="width: 100px">Servicio</th>
                <th>Última conexión</th>
                <th class="text-center" style="width: 50px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cpe in cpes" :key="cpe.id">
                <td>
                  <input
                    type="checkbox"
                    :checked="cpe._seleccionado"
                    @change="palancaSeleccionarCpe(cpe)"
                  />
                </td>
                <td>
                  <div style="height: 30px; display: inline-block">
                    <img
                      :src="API +
                        '/cpes/modelos/' +
                        cpe.id_modelo +
                        '/imagen?tipo_foto=64x64&_tk=' +
                        tk
                      "
                      v-if="cpe.id_foto_modelo != null"
                      class="icon-cpe"
                    />
                  </div>
                  <span
                    class="text-success"
                    v-if="cpe.en_linea == true"
                    title="Conectado"
                  >
                    <i class="fa fa-signal"></i>
                  </span>
                  <span
                    class="text-danger"
                    v-if="cpe.en_linea == false"
                    title="Desconectado"
                  >
                    <i class="fa fa-exclamation-circle"></i>
                  </span>
                </td>
                <td class="fw-semibold">
                  {{ cpe.id }}
                </td>
                <td>
                  <router-link 
                    :to="{
                      name: 'edicionCpe', 
                      params: { 
                        id: cpe.id
                      }
                    }">
                    {{ cpe.nombre }}
                  </router-link>
                </td>
                <td v-show="columnas.sitio">
                  {{ cpe.nombre_sitio }}
                </td>
                <td v-show="columnas.ip_wan" class="fw-semibold">
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>{{ cpe.ip_wan }}</span>
                    <button class="btn btn-light btn-xs" style="margin-left: 5px;" @click="mostrarMdlPing(cpe.id)">
                      <i class="mdi mdi-radar"></i>
                      Ping
                    </button>
                  </div>
                </td>
                <td v-show="columnas.mac">{{ cpe.mac_wan }}</td>
                <td v-show="columnas.cliente">
                  <router-link
                    :to="{
                      name: 'tableroCliente',
                      params: { id_cliente: cpe.id_cliente },
                    }"
                    v-if="cpe.nombre_cliente != undefined && cpe.id_cliente != null"
                  >
                    {{ cpe.nombre_cliente }}
                  </router-link>
                </td>
                <td v-show="columnas.estado">
                  <span
                    class="badge bg-danger bg-gradient font-size-13"
                    v-if="cpe.estado == 'descompuesto'"
                  >
                    Descompuesto
                  </span>
                  <span
                    class="badge bg-warning bg-gradient font-size-13"
                    v-if="cpe.estado == 'en reparación'"
                  >
                    En reparación
                  </span>
                  <span
                    class="badge bg-primary bg-gradient font-size-13"
                    v-if="cpe.estado == 'funcional'"
                  >
                    Funcional
                  </span>
                </td>
                <td v-show="columnas.servicio">
                  <span
                    class="badge badge-pill badge-soft-success font-size-13"
                    v-if="cpe.enlace_activo == 1"
                  >
                    Activo
                  </span>
                  <span
                    class="badge badge-pill badge-soft-danger font-size-13"
                    v-if="cpe.enlace_activo == 0"
                  >
                    Suspendido
                  </span>
                </td>
                <td>
                  {{ formatoFecha(cpe.ultimo_contacto) }} a las
                  {{ formatoFecha(cpe.ultimo_contacto, "hh:mm A") }}
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-show="cpe.pppoe == true || graficas_throughput_cpes == true">
                        <button
                          class="dropdown-item primary"
                          @click="mostrarMdlThroughPut(cpe.id)"
                          v-show="cpe.pppoe == true || graficas_throughput_cpes == true"
                        >
                          <i
                            class="mdi mdi-chart-bell-curve-cumulative font-size-15 text-primary"
                          ></i>
                          Throughput
                        </button>
                      </li>
                      <li>
                        <router-link
                          :to="{ name: 'edicionCpe', params: { id: cpe.id } }"
                          class="dropdown-item warning"
                        >
                          <i
                            class="mdi mdi-content-save-edit-outline font-size-15 text-warning"
                          ></i>
                          Editar
                        </router-link>
                      </li>
                      <li>
                        <button
                          class="dropdown-item danger"
                          @click="preguntaEliminarCpe(cpe)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline font-size-15 text-danger"
                          ></i>
                          Eliminar
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="(por_pagina = $event), refrescarCpes()"
      ></paginador>
    </div>

    <MdlCambiarIsp
      ref="MdlCambiarIsp"
      :ids_cpes_seleccionados="ids_cpes_seleccionados"
      @cpes-actualizados="deseleccionarTodosCpes()"
    />
    <MdlFiltrosCpes
      ref="MdlFiltrosCpes"
      :id_router_cpe.sync="id_router"
      :id_sitio_cpe="id_sitio"
      :id_marca_cpe="id_marca"
      :id_modelo_cpe="id_modelo"
      :sin_clientes_cpe="sin_clientes"
      :con_clientes_cpe="con_clientes"
      :con_pppoe_cpe="con_pppoe"
      @actualizar-cpes="actualizarCpes($event)"
    />
    <mdl-columnas
      ref="mdlColumnas"
      :sitio="columnas.sitio"
      :ip_wan="columnas.ip_wan"
      :mac="columnas.mac"
      :cliente="columnas.cliente"
      :estado="columnas.estado"
      :servicio="columnas.servicio"
      @actualizar-columnas="actualizarValoresColumnasEnLocalStorage($event)"
    ></mdl-columnas>
    <mdl-through-put
      ref="mdlthroughput"
      v-if="$store.state.todo.nombre_empresa != 'Livaur Internet 2'"
    ></mdl-through-put>
    <mdl-through-put-nuevo
      ref="mdlthroughput"
      v-if="$store.state.todo.nombre_empresa == 'Livaur Internet 2'"
    ></mdl-through-put-nuevo>

    <mdl-ping ref="mdlPing" />
    <br />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import API from "@/API.js"
import Paginador from "@/components/Paginador.vue"
import Filtrador from "@/components/Filtrador.vue"
import CmpSecciones from "./CmpSecciones.vue"
import CpeSrv from "@/services/CpeSrv.js"
import RouterSrv from "@/services/RouterSrv.js"
import IspRouterSrv from "@/services/IspRouterSrv.js"
import SitioSrv from "@/services/SitioSrv.js"
import SistemaSrv from "@/services/SistemaSrv.js"
import MdlCambiarIsp from "./MdlCambiarIsp.vue"
import MdlFiltrosCpes from "./MdlFiltrosCpes.vue"
import MdlColumnas from "./MdlColumnas.vue"

import MdlThroughPut from "./MdlThroughput.vue"
import MdlThroughPutNuevo from "./MdlThroughput-nuevo.vue"

import MdlPing from "./MdlPing.vue"

import Swal from "sweetalert2"
import moment from "moment"

export default {
  name: "ListadoCpes",
  components: {
    Layout,
    CmpSecciones,
    Paginador,
    Filtrador,
    MdlCambiarIsp,
    MdlFiltrosCpes,
    MdlColumnas,

    MdlThroughPut,
    MdlThroughPutNuevo,
    MdlPing
  },
  data() {
    return {
      tk: localStorage.getItem("argusblack.token"),
      API: API,
      baseUrl: window.location.origin,
      cpe: {},
      cpes: [],
      ids_cpes_seleccionados: [],
      dato: "",
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem("argusblack.listadoCpes.por_pagina") || 10),
      ultima_pagina: 1,
      filtros: "todos", // todos, conectados, suspendidos, desconectados, en_reparacion, descompuestos,
      id_router: null,
      id_sitio: null,
      id_marca: null,
      id_modelo: null,
      id_isp: null,
      sin_clientes: false,
      con_clientes: false,
      con_pppoe: false,

      router: { 
        id: null,
        nombre: ""
      },
      isp: { 
        id: null,
        nombre: "" 
      },
      sitios: [],
      routers: [],
      nCpesEnPag: 0,
      nCpesTotales: 0,
      graficas_throughput_cpes: 0,

      columnas: {
        sitio: true,
        ip_wan: true,
        mac: false,
        cliente: true,
        estado: false,
        servicio: true,
      },
    };
  },
  created: function () {
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.pagina) self.pagina = parseInt(self.$route.query.pagina)
    if (self.$route.query.por_pagina) self.por_pagina = parseInt(self.$route.query.por_pagina)
    if (self.$route.query.filtros) self.filtros = self.$route.query.filtros
    if (self.$route.params.id_router) self.id_router = self.$route.params.id_router
    else if (self.$route.query.id_router) self.id_router = self.$route.query.id_router
    if (self.$route.query.id_isp != null && self.$route.query.id_isp != undefined)
      self.id_isp = self.$route.query.id_isp
    if (self.$route.query.id_sitio) self.id_sitio = self.$route.query.id_sitio
    if (self.$route.query.id_marca) self.id_marca = self.$route.query.id_marca
    if (self.$route.query.id_modelo) self.id_modelo = self.$route.query.id_modelo
    if (self.$route.query.sin_clientes)
      self.sin_clientes = self.$route.query.sin_clientes == "true" ? true : false
    if (self.$route.query.con_clientes)
      self.con_clientes = self.$route.query.con_clientes == "true" ? true : false
    if (self.$route.query.con_pppoe)
      self.con_pppoe = self.$route.query.con_pppoe == "true" ? true : false

    // Control de eventos
    self.inicio()
    self.cargarSitios()
    self.cargarRoutersXSitio()
    self.cargarBanderaGraficasThroughputCpes()

    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },
  watch: {
    "$route.query.id_router": function () {
      var self = this
      if (this.$route.query.id_router == undefined) {
        self.id_router = null
        self.router = { 
          nombre: ""
        }
        self.refrescarCpes()
      }
    },
  },
  methods: {
    actualizarCpes(event) {
      var self = this

      self.id_sitio = event.id_sitio
      self.id_router = event.id_router
      self.id_marca = event.id_marca
      self.id_modelo = event.id_modelo
      self.sin_clientes = event.sin_clientes
      self.con_clientes = event.con_clientes
      self.con_pppoe = event.con_pppoe

      if (self.id_router != null) self.cargarRouter()

      self.refrescarCpes()
    },

    actualizarValoresColumnasEnLocalStorage(event) {
      let self = this

      self.columnas.sitio = event.sitio
      self.columnas.ip_wan = event.ip_wan
      self.columnas.mac = event.mac
      self.columnas.cliente = event.cliente
      self.columnas.estado = event.estado
      self.columnas.servicio = event.servicio

      localStorage.setItem(
        "argusblack.listadoCpes.columnas",
        JSON.stringify(self.columnas)
      )
    },

    cargarBanderaGraficasThroughputCpes() {
      let self = this
      SistemaSrv.valorAjuste("graficas_throughput_cpes").then((response) => {
        self.graficas_throughput_cpes = response.data
      })
    },

    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem("argusblack.listadoCpes.columnas")

      if (columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },

    cargarPagina: function (n) {
      var self = this
      self.pagina = n
      self.refrescarCpes()
    },

    cargarIsp: function () {
      var self = this

      IspRouterSrv.ispJSON(self.id_isp).then((response) => {
        self.isp = response.data
      }).catch((error) => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudo cargar el ISP"
        }
        iu.msg.error(mensaje)
        console.log(error)
      });
    },

    cargarRouter: function () {
      var self = this

      if (self.id_router == null) {
        self.router = { 
          id: null, 
          nombre: "" 
        }
        return
      }

      RouterSrv.routerJSON(self.id_router).then((response) => {
        self.router = response.data
      }).catch((error) => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudo cargar el router"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarSitios: function () {
      var self = this

      SitioSrv.sitiosJSON().then((response) => {
        self.sitios = response.data
      }).catch((error) => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudieron refrescar los sitios"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    deseleccionarTodosCpes() {
      var self = this

      self.ids_cpes_seleccionados = []
      self.cpes.forEach(function (cpe) {
        cpe._seleccionado = false
      })
    },

    formatoFecha(mt, formato = "DD/MM/YYYY") {
      return moment(mt, "YYYY-MM-DD HH:mm:ss").format(formato)
    },

    inicio: function () {
      var self = this

      // Cargas iniciales
      this.refrescarCpes()

      if (self.id_router != null) self.cargarRouter()
      if (self.id_isp != null) self.cargarIsp()
    },

    mostrarMdlCambiarIsp: function () {
      var self = this
      self.$refs.MdlCambiarIsp.mostrar()
    },

    mostrarMdlColumnas: function () {
      var self = this
      self.$refs.mdlColumnas.mostrar()
    },

    mostrarMdlFiltrosCpes: function () {
      var self = this
      self.$refs.MdlFiltrosCpes.mostrar()
    },

    mostrarMdlThroughPut: function (idCpe) {
      var self = this
      self.$refs.mdlthroughput.mostrar(idCpe)
    },

    mostrarMdlPing: function (idCpe) {
      var self = this
      self.$refs.mdlPing.mostrar(idCpe)
    },

    palancaSeleccionarCpe(cpe) {
      let self = this
      let index = self.ids_cpes_seleccionados.indexOf(cpe.id)

      cpe._seleccionado = !cpe._seleccionado

      if (cpe._seleccionado && index == -1) self.ids_cpes_seleccionados.push(cpe.id)

      if (!cpe._seleccionado && index >= 0) self.ids_cpes_seleccionados.splice(index, 1)
    },

    palancaSeleccionarTodosCpes() {
      let self = this
      let todosSeleccionados = self.ids_cpes_seleccionados.length == self.cpes.length

      if (!todosSeleccionados) {
        self.ids_cpes_seleccionados = []

        self.cpes.forEach(function (cpe) {
          self.ids_cpes_seleccionados.push(cpe.id)
          cpe._seleccionado = true
        })
      } else {
        self.ids_cpes_seleccionados = []

        self.cpes.forEach(function (cpe) {
          cpe._seleccionado = false
        })
      }
    },

    preguntaEliminarCpe: function (cpe) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      })

      swal.fire({
        title: "Eliminación del CPE",
        html:
          "¿Está seguro que desea eliminar el cpe <strong>" +
          cpe.nombre +
          "</strong> ?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          CpeSrv.eliminar(cpe.id).then((response) => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarCpes()
          }).catch((error) => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo eliminar el CPE"
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },

    refrescarCpes(config) {
      var self = this

      iu.spinner.mostrar("#tabla-cpes")

      var query = {
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.por_pagina,
        filtros: self.filtros,
        sin_clientes: self.sin_clientes,
        con_clientes: self.con_clientes,
        con_pppoe: self.con_pppoe
      }

      if (self.id_router != null) query.id_router = self.id_router
      if (self.id_isp != null) query.id_isp = self.id_isp
      if (self.id_sitio != null) query.id_sitio = self.id_sitio
      if (self.id_marca != null) query.id_marca = self.id_marca
      if (self.id_modelo != null) query.id_modelo = self.id_modelo

      self.$router.replace({ query: query }).catch((e) => {})

      config = config || {}
      if (config.pagina == undefined) config.pagina = self.pagina
      if (config.por_pagina == undefined) config.por_pagina = self.por_pagina
      if (config.dato == undefined && self.dato != "") config.dato = self.dato

      switch (self.filtros) {
        case "todos": // todos
          config.todos = true
          config.con_descompuestos = true
          break
        case "conectados": // solo_conectados
          config.con_descompuestos = true
          config.solo_conectados = true
          break
        case "suspendidos": // sin_internet
          config.sin_internet = true
          break
        case "desconectados": // solo_desconectados
          config.solo_desconectados = true
          break
        case "en_reparacion": // en_reparacion
          config.en_reparacion = true
          break
        case "descompuestos": // descompuesto
          config.descompuesto = true
          config.con_descompuestos = true
          break
      }

      if (self.id_router != null) config.id_router = self.id_router
      if (self.id_isp != null) config.id_isp = self.id_isp
      if (self.id_sitio != null) config.id_sitio = self.id_sitio
      if (self.id_marca != null) config.id_marca = self.id_marca
      if (self.id_modelo != null) config.id_modelo = self.id_modelo
      if (self.sin_clientes) config.sin_clientes = self.sin_clientes
      if (self.con_clientes) config.con_clientes = self.con_clientes
      if (self.con_pppoe) config.con_pppoe = self.con_pppoe

      CpeSrv.vCpes1JSON(config).then((response) => {
        localStorage.setItem("argusblack.listadoCpes.por_pagina", self.por_pagina)
        let res = response.data

        res.cpes.forEach(function (cpe) {
          cpe._seleccionado = false
        })

        self.cpes = res.cpes
        self.pagina = res.pagina
        self.ultima_pagina = res.ultima_pagina
        self.nCpesEnPag = res.nCpesEnPag
        self.nCpesTotales = res.nCpesTotales

        if (self.ultima_pagina < self.pagina) {
          self.pagina = self.ultima_pagina
          self.refrescarCpes()
        }
      }).catch((error) => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudieron cargar los CPEs"
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar("#tabla-cpes")
      })
    },

    cargarRoutersXSitio: function () {
      var self = this
      let idSitio = self.id_sitio == null ? 0 : self.id_sitio

      RouterSrv.routerSitioJSON(idSitio).then((response) => {
        self.routers = response.data
      }).catch((error) => {
        if (self.id_sitio != -1) {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = "No se pudieron cargar los routers"
          }
          iu.msg.error(mensaje)
        }
      })
    },
  },
  mounted() {
    iu.spinner.mostrar("#tabla-cpes")
  },
}
</script>

<style scoped>
.form-check-input-success:checked {
  background-color: #63ad6f;
  color: #63ad6f;
  border-color: #63ad6f;
}
.form-check-input-primary:checked {
  background-color: #3b76e1;
  border-color: #3b76e1;
}
.form-check-input-warning:checked {
  background-color: #f9c256;
  border-color: #f9c256;
}
.form-check-input-danger:checked {
  background-color: #f56e6e;
  border-color: #f56e6e;
}
.form-check-input-info:checked {
  background-color: #57c9eb;
  border-color: #57c9eb;
}
.icon-cpe {
  width: 30px;
  height: 30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>
