<template>
  <div class="modal fade" ref="mdlFiltrosCpes" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Filtros CPEs</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Da click sobre el switch deseado para realizar el filtrado:
          </p>

          <div class="row">
            <div class="col-md-6 mb-3">
              <label>Sitio de administración</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="mdi mdi-map-marker-radius-outline"></i>&nbsp;Sitio
                </span>
                <select
                  class="form-select"
                  v-model="id_sitio"
                  @change="
                    id_router=null,
                    cargarRoutersXSitio(),
                    actualizarCpes()
                  "
                >
                  <option :value="null">Todos</option>
                  <option :value="-1">Sin sitio</option>
                  <option v-for="sitio in sitios" :key="sitio.id" :value="sitio.id">
                    {{ sitio.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label>Router de administración</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="mdi mdi-router"></i>&nbsp;Router
                </span>
                <select
                  class="form-select"
                  v-model="id_router"
                  @change="actualizarCpes()"
                >
                  <option :value="null">Todos</option>
                  <option
                    v-for="router in routers"
                    :key="router.id"
                    :value="router.id"
                    >{{ router.nombre }}</option
                  >
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label>Marca del cpe</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="mdi mdi-map-marker-radius-outline"></i>&nbsp;
                  Marca
                </span>
                <select
                  class="form-select"
                  v-model="id_marca"
                  @change="
                    id_modelo = null,
                    cargarModelosCpes(),
                    actualizarCpes()
                  "
                >
                  <option :value="null">Todos</option>
                  <option v-for="marca in marcas" :key="marca.id" :value="marca.id">
                    {{ marca.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label>Modelo del cpe</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="mdi mdi-router"></i>&nbsp;
                  Modelo
                </span>
                <select
                  class="form-select"
                  v-model="id_modelo"
                  @change="actualizarCpes()"
                >
                  <option :value="null">Todos</option>
                  <option
                    v-for="modelo in modelos"
                    :key="modelo.id"
                    :value="modelo.id"
                  >
                    {{ modelo.identificador }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="con_pppoe"
                  @change="actualizarCpes()"
                  class="form-check-input"
                  type="checkbox"
                  id="conPppoe"
                />
                <label class="form-check-label" for="conPppoe">Con PPPoE</label>
              </div>
            </div>
          </div>
          <h5>Clientes de los CPEs</h5>
          <hr>
          <div class="row">
            <div class="col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="con_clientes"
                  @change="actualizarCpes()"
                  class="form-check-input"
                  type="checkbox"
                  id="conClientes"
                />
                <label class="form-check-label" for="conClientes">Con clientes asignados</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="sin_clientes"
                  @change="actualizarCpes()"
                  class="form-check-input"
                  type="checkbox"
                  id="sinClientes"
                />
                <label class="form-check-label" for="sinClientes">Sin clientes asignados</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import RouterSrv from '@/services/RouterSrv.js'
import SitioSrv from '@/services/SitioSrv.js'
import MarcaCpesSrv from '@/services/MarcaCpesSrv.js'
import ModeloCpesSrv from '@/services/ModeloCpesSrv.js'

export default {
  name: 'MdlFiltrosCpes',
  props: {
    id_router_cpe: {
      type: Number,
      default: null
    },
    id_sitio_cpe: {
      type: Number,
      default: null
    },
    id_marca_cpe: {
      type: Number,
      default: null
    },
    id_modelo_cpe: {
      type: Number,
      default: null
    },
    sin_clientes_cpe: {
      type: Boolean,
      default: false
    },
    con_clientes_cpe: {
      type: Boolean,
      default: false
    },
    con_pppoe_cpe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id_sitio: this.id_sitio_cpe,
      id_router: this.id_router_cpe,
      id_marca: this.id_marca_cpe,
      id_modelo: this.id_modelo_cpe,
      sin_clientes: this.sin_clientes_cpe,
      con_clientes: this.con_clientes_cpe,
      con_pppoe: this.con_pppoe_cpe,
      routers: [],
      sitios: [],
      marcas: [],
      modelos: []
    }
  },
  methods: {
    actualizarCpes() {
      let self = this

      self.$emit('actualizar-cpes', {
        id_sitio: self.id_sitio,
        id_router: self.id_router,
        id_marca: self.id_marca,
        id_modelo: self.id_modelo,
        sin_clientes: self.sin_clientes,
        con_clientes: self.con_clientes,
        con_pppoe: self.con_pppoe,
      })
    },

    cargarSitios: function() {
      var self = this

      SitioSrv.sitiosJSON().then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMarcas: function() {
      var self = this

      MarcaCpesSrv.marcasJSON().then(response => {
        self.marcas = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas del cpe'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarModelosCpes: function() {
      var self = this
      let idMarca = self.id_marca == null ? null : self.id_marca

      let params = {
        id_marca: idMarca
      }

      ModeloCpesSrv.modelosJSON(params).then(response => {
        self.modelos = response.data
      }).catch(error => {
        if (self.id_marca != -1){
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudieron cargar los modelos del cpe'
          }
          iu.msg.error(mensaje)
        }
      })
    },

    cargarRoutersXSitio: function() {
      var self = this
      let idSitio = self.id_sitio == null ? 0 : self.id_sitio

      RouterSrv.routerSitioJSON(idSitio).then(response => {
        self.routers = response.data
      }).catch(error => {
        if (self.id_sitio != -1){
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudieron cargar los routers'
          }
          iu.msg.error(mensaje)
        }
      })
    },

    mostrar() {
      var self = this
      self.cargarSitios()
      self.cargarRoutersXSitio()
      self.cargarMarcas()
      self.cargarModelosCpes()
      var modal = new Modal(this.$refs.mdlFiltrosCpes)
      modal.show()
    },
  }
}
</script>
